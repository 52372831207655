/*eslint-disable */
// import ConfigMenuModel from '../../Model/ConfigMenu'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let ConfigMenus = {
  /**
   * configMenuList
   */
  async configMenuList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }
      return await request.curl(context, "admin_config_menu_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at configMenuList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * configMenuView
   */
  async configMenuView (context, configMenuId) {
    try {      
      let post_data = new FormData();
      post_data.append('acm_id', configMenuId);
      return await request.curl(context, "admin_config_menu_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at configMenuView() and Exception:",err.message)
    }    
  },

  /**
   * configMenuAdd
   */
  async configMenuAdd (context, configMenuObj) {
    try{
      let post_data = new FormData();
    
      for (let key in configMenuObj) {
        post_data.append(key, configMenuObj[key]);
      }

      return await request.curl(context, "admin_config_menu_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at configMenuAdd() and Exception:',err.message)
    }
  },

  /**
   * configMenuEdit
   */
  async configMenuEdit (context, configMenuObj) {
    try{
      let post_data = new FormData();
    
      for (let key in configMenuObj) {
        post_data.append(key, configMenuObj[key]);
      }

      return await request.curl(context, "admin_config_menu_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at configMenuEdit() and Exception:',err.message)
    }
  },

  /**
   * configMenuDelete
   */
  async configMenuDelete (context, configMenuId) {
    try{
      let post_data = new FormData();
      
      post_data.append('acm_id', configMenuId);

      return await request.curl(context, "admin_config_menu_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at configMenuDelete() and Exception:',err.message)
    }
  }
}

export {
  ConfigMenus
}
